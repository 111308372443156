import React from 'react';
import { Container, Typography, Link, Box } from '@mui/material';

const Connect = () => {
  return (
    <Box sx={{ py: 8, px: 4, bgcolor: 'secondary.main', color: 'text.secondary' }}>
      <Container maxWidth="md">
        <Typography variant="h4" component="h2" gutterBottom>
          Connect With Us
        </Typography>
        <Typography variant="body1">
          Follow us on <Link href="https://instagram.com" color="primary">Instagram</Link> and
          chat with us on <Link href="https://api.whatsapp.com/send?phone=YOURNUMBER" color="primary">WhatsApp</Link>.
        </Typography>
      </Container>
    </Box>
  );
}

export default Connect;
