import React from 'react';
import Carousel from './components/Carousel';
import Navbar from './components/Navbar1.js';
import About from './components/About';
import Appointment from './components/Appointment.js';
import Reviews from './components/Reviews';
import Connect from './components/Connect';
import Footer from './components/Footer';
import './index.css';



function App() {
  return (
    <div className="App">
<Navbar/>
      <main>
        <Carousel />
        <About />
        <Appointment />
        <Reviews />
        <Connect />
      </main>
      <Footer />
    </div>
  );
}

export default App;
