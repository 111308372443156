import React from 'react';
import { Container, Typography, Link, Box } from '@mui/material';

const Footer = () => {
  return (
    <Box sx={{ bgcolor: 'primary.main', color: 'white', py: 4, mt: 8 }}>
      <Container maxWidth="md" sx={{ textAlign: 'center' }}>
        <Typography variant="body2">
          © {new Date().getFullYear()} Carpenter's Portfolio. All rights reserved.
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          Contact us at <Link href="mailto:info@carpentersportfolio.com" color="inherit" underline="always">info@carpentersportfolio.com</Link>
        </Typography>
        <Typography variant="body2">
          Call us: <Link href="tel:+1234567890" color="inherit" underline="always">+1 234 567 890</Link>
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;
