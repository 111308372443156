import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Container, Box } from '@mui/material';

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    lazyLoad: 'ondemand'
  };

  return (
    <Container maxWidth="md" sx={{ mt: 8 }}>
      <Slider {...settings}>
        <Box>
          <img src="/Assets/Sample1.webp" alt="Work example 1 showing detailed carpentry" style={{ width: '100%', height: '80vh', objectFit: 'cover' }} />
        </Box>
        <Box>
          <img src="/Assets/Sample2.webp" alt="Work example 2 showcasing custom woodwork" style={{ width: '100%', height: '80vh', objectFit: 'cover' }} />
        </Box>
        {/* Add more slides as needed */}
      </Slider>
    </Container>
  );
}

export default Carousel;
