import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';

const About = () => {
  return (
    <Box id="about" sx={{ py: 8, px: 4, bgcolor: 'background.default' }}>
      <Container maxWidth="md">
        <Typography variant="h4" component="h2" gutterBottom>
          About Us
        </Typography>
        <Paper elevation={3} sx={{ p: 6 }}>
          <Typography variant="body1">
            Our carpentry services encompass a broad spectrum of tasks tailored to elevate both the functionality and aesthetics of your home. We excel in every aspect, from the precision installation of doors to the restoration of rotten ones, ensuring seamless transitions and enhanced security throughout your living space. Cavity sliding doors are seamlessly integrated, optimizing both space utilization and style. Our decking projects are meticulously crafted for outdoor enjoyment, utilizing premium materials to guarantee longevity and durability against the elements. Structural framing forms the foundation of stability, meticulously constructed to exceed safety standards. Artful arcitraves provide a touch of finesse, framing interiors with timeless elegance. Skirtings are flawlessly installed to deliver a polished finish to any room. In addition, we specialize in addressing issues such as rotten floorboards and water damage in bathrooms, employing our expertise with meticulous attention to detail to restore both integrity and charm to your home.
          </Typography>
        </Paper>
      </Container>
    </Box>
  );
}

export default About;
