import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';

const Appointment = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        date: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);

        fetch('http://localhost:3002/send-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then(response => response.json())
        .then(data => alert('Email sent successfully'))
        .catch(error => {
            console.error('Error:', error);
            alert('Error sending email');
        });
    };

    return (
        <Container id="appointment" sx={{ py: 8 }}>
            <Typography variant="h4" component="h2" gutterBottom>
                Book an Appointment
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField
                    label="Your Name"
                    variant="outlined"
                    name="name"
                    fullWidth
                    onChange={handleChange}
                />
                <TextField
                    label="Your Email"
                    variant="outlined"
                    type="email"
                    name="email"
                    fullWidth
                    onChange={handleChange}
                />
                <TextField
                    label="Date"
                    variant="outlined"
                    type="date"
                    name="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                />
                <TextField
                    label="Message"
                    variant="outlined"
                    name="message"
                    multiline
                    rows={4}
                    fullWidth
                    onChange={handleChange}
                />
                <Button type="submit" variant="contained" color="primary">
                    Submit
                </Button>
            </Box>
        </Container>
    );
};

export default Appointment;
