import React from 'react';
import { Container, Typography, Grid, Paper, Box, Avatar } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const Reviews = () => {
  const reviews = [
    { id: 1, name: "John Doe", text: "Excellent craftsmanship and attention to detail!", rating: 5 },
    { id: 2, name: "Jane Smith", text: "Very professional and delivered on time.", rating: 4 },
    { id: 3, name: "Emily Johnson", text: "Outstanding service! The team went above and beyond to meet our needs.", rating: 5 },
    // More reviews
  ];

  return (
    <Box id="reviews" sx={{ py: 8, px: 4 }}>
      <Container maxWidth="lg">
        <Typography variant="h4" component="h2" gutterBottom>
          Customer Reviews
        </Typography>
        <Grid container spacing={4}>
          {reviews.map(review => (
            <Grid item key={review.id} xs={12} md={6} lg={4}>
              <Paper elevation={3} sx={{ p: 3 }}>
                <Box display="flex" alignItems="center" mb={2}>
                  <Avatar alt={review.name} src={`https://picsum.photos/50?random=${review.id}`} sx={{ mr: 2 }} />
                  <Typography variant="subtitle1" fontWeight="bold">
                    {review.name}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={2}>
                  {[...Array(review.rating)].map((_, index) => (
                    <FontAwesomeIcon key={index} icon={faStar} style={{ color: '#ffb400', marginRight: '4px' }} />
                  ))}
                </Box>
                <Typography variant="body2" color="textSecondary">
                  {review.text}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default Reviews;
