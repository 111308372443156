import React from 'react';
import { AppBar, Toolbar, Typography, Button, Container } from '@mui/material';

function Navbar() {
  const handleSmoothScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <AppBar position="static" color="default">
      <Container maxWidth="md">
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          <Typography variant="h6" color="inherit" noWrap>
            Carpenter's Portfolio
          </Typography>
          <div>
            <Button color="primary" onClick={() => handleSmoothScroll('about')} sx={{ mx: 1 }}>
              About
            </Button>
            <Button color="primary" onClick={() => handleSmoothScroll('appointment')} sx={{ mx: 1 }}>
              Appointment
            </Button>
            <Button color="primary" onClick={() => handleSmoothScroll('reviews')} sx={{ mx: 1 }}>
              Reviews
            </Button>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;
